import {SideNavigation, DashboardFragment} from "./shared/navigation";
import {createCandidateResumeUrl, appliedJobsUrl, shortlistedJobsUrl} from "./constants";
import {TemplateFragmentLoader} from "./templates";
import {createCandidateProfileUrl} from "./constants";

class CandidateDashboardFragment extends DashboardFragment {
    constructor(fragmentName, isLoaded, url) {
        super(fragmentName, isLoaded, url);
    }

    isCandidateProfileFragment() {
        return this.fragmentName === 'candidate-profile';
    }

    isResumeFragment() {
        return this.fragmentName === 'resume';
    }

    isAppliedJobsFragment() {
        return this.fragmentName === 'applied-jobs';
    }

    isShortlistedJobsFragment() {
        return this.fragmentName === 'shortlisted-jobs';
    }

}

export class CandidateSideNavigation extends SideNavigation {

    constructor(injectIntoId) {
        super();
        this.injectIntoId = injectIntoId;
        this.fragments = [
        new CandidateDashboardFragment("candidate-profile", false, createCandidateProfileUrl),
        new CandidateDashboardFragment("resume", false, createCandidateResumeUrl),
        new CandidateDashboardFragment("applied-jobs", false, appliedJobsUrl),
        new CandidateDashboardFragment("shortlisted-jobs", false, shortlistedJobsUrl)
    ];
    }

    loadCandidateProfile() {
        this.fragments
            .filter(fragment => !fragment.isCandidateProfileFragment())
            .forEach(fragment => fragment.isLoaded = false);
        this.load(this.fragments
            .find(fragment => fragment.isCandidateProfileFragment()))
    }

    loadResume(url) {
        this.fragments
            .filter(fragment => !fragment.isResumeFragment())
            .forEach(fragment => fragment.isLoaded = false);
        let resumeFragment = this.fragments.find(fragment => fragment.isResumeFragment());
        resumeFragment.url = url;
        this.load(resumeFragment);
    }

    loadShortlistedJobs() {
        this.fragments
            .filter(fragment => !fragment.isShortlistedJobsFragment())
            .forEach(fragment => fragment.isLoaded = false);
        let shortlistedJobsFragment = this.fragments.find(fragment => fragment.isShortlistedJobsFragment());
        this.load(shortlistedJobsFragment);
    }

    loadAppliedJobs() {
        this.fragments
            .filter(fragment => !fragment.isAppliedJobsFragment())
            .forEach(fragment => fragment.isLoaded = false);
        let appliedJobsFragment = this.fragments.find(fragment => fragment.isAppliedJobsFragment());
        this.load(appliedJobsFragment);
    }

    load(fragment) {
        const loader = new TemplateFragmentLoader(this.injectIntoId, fragment.url);
        loader.loadTemplateFragment();
        fragment.isLoaded = true;
    }
}

export class CandidateDashboard {
    constructor() {
        this.resumeModal = new CandidateResumeModal();
    }

    // modal used for various pop ups
    initModal() {
        const elem = document.getElementById("update-candidate-info");
        const modal = new bootstrap.Modal(elem)
        htmx.on("htmx:afterSwap", (e) => {
            // Response targeting #dialog => show the modal
            if (e.detail.target.id == "update-candidate-info-form") {
              modal.show()
            }
        })
    }
    initialize() {
        this.initModal();
    }
    initializeResumeModal() {
        this.resumeModal.initialize();
    }
    initializeDatePickers() {
        this.resumeModal.initializeDatePickers();
    }
}

export class CandidateResumeModal {
    constructor() {
    }


    selectizeSkills() {
        $("#id_skills").selectize({
            delimiter: ",",
            persist: false,
            create: function (input) {
              return {
                  value: input,
                  text: input,
              };
            },
          });
    }
    initializeDatePickers() {
        $('#id_start_date').datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            changeYear: true,
            changeMonth: true,
            clearBtn:true
        });

        $('#id_end_date').datepicker({
            format: 'dd-mm-yyyy',
            autoclose: true,
            changeYear: true,
            changeMonth: true,
            clearBtn:true
        });
    }
    initialize() {
        this.selectizeSkills();
    }
}
