import {createCompanyProfileUrl, jobFormUrl, employerJobList, employerJobApplicationList} from "./constants";
import {TemplateFragmentLoader} from "./templates";
import {SideNavigation, DashboardFragment} from "./shared/navigation"

class EmployerDashboardFragment extends DashboardFragment {
    constructor(fragmentName, isLoaded, url) {
        super(fragmentName, isLoaded, url);
    }

    isNewJobFragment() {
        return this.fragmentName === 'new-job';
    }
    isCompanyProfileFragment() {
        return this.fragmentName === 'company-profile';
    }

    isManagedJobsFragment() {
        return this.fragmentName === 'manage-jobs';
    }

    isJobApplicationsFragment() {
        return this.fragmentName === 'jobApplications';
    }

}

export class EmployerSideNavigation extends SideNavigation {

    constructor(injectIntoId) {
        super();
        this.injectIntoId = injectIntoId;
        this.fragments = [
        new EmployerDashboardFragment("new-job", false, jobFormUrl),
        new EmployerDashboardFragment("company-profile", false, createCompanyProfileUrl),
        new EmployerDashboardFragment("manage-jobs", false, employerJobList),
        new EmployerDashboardFragment("jobApplications", false, employerJobApplicationList)
    ];
    }

    loadCompanyProfile() {
        this.fragments
            .filter(fragment => !fragment.isCompanyProfileFragment())
            .forEach(fragment => fragment.isLoaded = false);
        this.load(this.fragments
            .find(fragment => fragment.isCompanyProfileFragment()))
    }
    loadNewJob(url) {
        this.fragments
            .filter(fragment => !fragment.isNewJobFragment())
            .forEach(fragment => fragment.isLoaded = false);
        let newJobFragment = this.fragments.find(fragment => fragment.isNewJobFragment());
        newJobFragment.url = url;
        this.load(newJobFragment)
    }
    loadEmployerJobList() {
        this.fragments
            .filter(fragment => !fragment.isManagedJobsFragment())
            .forEach(fragment => fragment.isLoaded = false);
        this.load(this.fragments
            .find(fragment => fragment.isManagedJobsFragment()))
    }
    loadJobApplicationList() {
        this.fragments
            .filter(fragment => !fragment.isJobApplicationsFragment())
            .forEach(fragment => fragment.isLoaded = false);
        this.load(this.fragments
            .find(fragment => fragment.isJobApplicationsFragment()))
    }
    load(fragment) {
        const loader = new TemplateFragmentLoader(this.injectIntoId, fragment.url);
        loader.loadTemplateFragment();
        fragment.isLoaded = true;
    }
}

export class NewJobUI {
    constructor() {
    }

    init() {
        this.selectizeSkills();
        this.selectizeLanguages();
        this.initDatePickers();
    }
    selectizeSkills() {
        $("#id_skills").selectize({
            delimiter: ",",
            persist: false,
            create: function (input) {
              return {
                  value: input,
                  text: input,
              };
            },
          });
    }

    selectizeLanguages() {
        $('#id_languages').selectize({
            plugins: ["clear_button", "remove_button", "auto_position"],
            create: false
          });
    }

    initDatePickers() {
        $('#id_start_date').datepicker({
            format: 'dd-mm-yyyy',
            startDate: '-3d',
            autoclose: true,
            changeYear: true,
            changeMonth: true,
            clearBtn:true
        });

        $('#id_application_deadline').datepicker({
            format: 'dd-mm-yyyy',
            startDate: '-3d',
            autoclose: true,
            changeYear: true,
            changeMonth: true,
            clearBtn:true
        });
    }

}

export class ManageJobsUI {
    constructor() {
    }

    init() {
        this.initDeleteJobModal();
    }

    initDeleteJobModal() {
        const elem = document.getElementById("update-employer-info");
        const modal = new bootstrap.Modal(elem)
        htmx.on("htmx:afterSwap", (e) => {
            // Response targeting #dialog => show the modal
            if (e.detail.target.id == "update-employer-info-form") {
              modal.show()
            }
        })
    }
}

export class EmployerDashboard {
    constructor() {
        this.newJobUi = new NewJobUI();
        this.manageJobsUi = new ManageJobsUI();
    }

    laodNewJobUi() {
        this.newJobUi.init();
    }

    loadManageJobsUi() {
        this.manageJobsUi.init();
    }

    initialize() {
        this.loadManageJobsUi();
        this.laodNewJobUi();
    }
}
